import axios from 'axios'
import Utils from '@/services/Utils'

export default {
  getAuthorization: function () {
    const user = process.env.VUE_APP_EMAIL
    const password = process.env.VUE_APP_PASSWORD
    return 'Basic ' + window.btoa(user + ':' + password)
  },
  getVideo(data, callback) {
    axios
      .get(axios.defaults.storageUrl + 'file?filter={"id":' + data + '}&lng=' + window.language, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: this.getAuthorization(),
        },
      })
      .then((response) => {
        Utils.processResponse(response, callback)
      })
      .catch((response) => {
        Utils.processResponse(response, callback)
      })
  },
  sendVideo(data, callback) {
    const self = this
    get()

    function get() {
      axios
        .get(axios.defaults.storageUrl + 'file?filter={"name":"' + data.name + '"}&website=1&lng=' + window.language, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: self.getAuthorization(),
          },
        })
        .then((response) => {
          Utils.processResponse(response, send)
        })
        .catch((response) => {
          Utils.processResponse(response, send)
        })
    }

    function send(files) {
      if (files && files.data && files.data[0] && files.data[0].id) {
        axios
          .patch(axios.defaults.storageUrl + 'file?id=' + files.data[0].id + '&website=1&lng=' + window.language, data, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: self.getAuthorization(),
            },
          })
          .then((response) => {
            Utils.processResponse(response, callback)
          })
          .catch((response) => {
            Utils.processResponse(response, callback)
          })
      } else {
        axios
          .post(axios.defaults.storageUrl + 'file?website=1&lng=' + window.language, data, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: self.getAuthorization(),
            },
          })
          .then((response) => {
            Utils.processResponse(response, callback)
          })
          .catch((response) => {
            Utils.processResponse(response, callback)
          })
      }
    }
  },
  getMessageVideo(data, callback) {
    axios
      .get(axios.defaults.storageUrl + 'file?base64=true&filter={"id":' + data + '}&website=1&lng=' + window.language, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: this.getAuthorization(),
        },
      })
      .then((response) => {
        Utils.processResponse(response, callback)
      })
      .catch((response) => {
        Utils.processResponse(response, callback)
      })
  },
}
